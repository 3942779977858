import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const searchInvoices = async ({
  limit = 20,
  skip = 0,
  filterModelOptions,
  filterSerieOptions,
  filterOperationOptions,
  filterCompanyOptions,
  filterUserOptions,
  searchText = '',
  sortBy = 'createdAt',
  sortDirection = 'desc',
  startDate,
  endDate,
  setInvoicesList,
  setLoadingInvoicesList = () => {},
  setCountInvoices = () => {},
  setSnackbar = () => {},
}) => {
  try {
    setLoadingInvoicesList(true);

    const response = await Axios.get('/invoices/search', {
      params: {
        limit,
        skip,
        filterModelOptions,
        filterSerieOptions,
        filterOperationOptions,
        filterCompanyOptions,
        filterUserOptions,
        searchText,
        sortBy,
        sortDirection,
        startDate,
        endDate,
      },
    });

    const { invoicesList, totalResults } = response.data;

    setInvoicesList(invoicesList);
    setCountInvoices(totalResults);

    return setLoadingInvoicesList(false);
  } catch (error) {
    setLoadingInvoicesList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao pesquisar NF-e',
      error,
    });
  }
};

export const createInvoices = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  ordersIds,
  previaDanfe = false,
}) => {
  try {
    setLoadingInvoices(true);
    const response = await Axios.post('/invoices', { ordersIds, previaDanfe });
    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao emitir NF-e',
      error,
    });

    return null;
  }
};

export const createInvoicesDevolutions = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  devolutionsIds,
}) => {
  try {
    setLoadingInvoices(true);
    const response = await Axios.post('/invoices/devolutions', { devolutionsIds });
    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao emitir NF-e de devolução',
      error,
    });

    return null;
  }
};

export const createInvoiceCorrection = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  invoiceKey,
  correction,
}) => {
  try {
    setLoadingInvoices(true);

    const response = await Axios.post('/invoices/correction', {
      invoiceKey,
      correction,
    });

    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar carta de correção da NF-e',
      error,
    });

    return null;
  }
};

export const cancelInvoice = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  orderId,
  reason,
}) => {
  try {
    setLoadingInvoices(true);

    const response = await Axios.put('/invoices/cancel', {
      orderId,
      reason,
    });

    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao cancelar NF-e',
      error,
    });

    return null;
  }
};

export const getInvoicesResume = async ({
  setLoadingInvoicesResume,
  setFilterInvoicesStatus,
  filtersInvoicesStatus,
  filterModelOptions,
  filterSerieOptions,
  filterOperationOptions,
  filterCompanyOptions,
  filterUserOptions,
  setSnackbar = () => {},
  searchText,
  startDate,
  endDate,
}) => {
  try {
    setLoadingInvoicesResume(true);

    const response = await Axios.get(`/invoices/resume`, {
      params: {
        filtersInvoicesStatus,
        filterModelOptions,
        filterSerieOptions,
        filterOperationOptions,
        filterCompanyOptions,
        filterUserOptions,
        searchText,
        startDate,
        endDate,
      },
    });

    setFilterInvoicesStatus(response.data);
    return setLoadingInvoicesResume(false);
  } catch (error) {
    setLoadingInvoicesResume(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao buscar o resumo das Notas Fiscais',
      error,
    });
  }
};
