import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import { PrintOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { formatDate, formatHandleError } from '../../../helpers/formatData';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import CustomPagination from '../../Common/CustomPagination';
import SimpleDialog from '../../Common/SimpleDialog';
import OrderDetails from '../../Orders/Details';
import { getOrder } from '../../../services/melhorGestao/orders';

const useStyles = makeStyles({
  smallCell: {
    width: 13,
  },
  mediumCell: {
    width: 140,
  },
  printButton: {
    cursor: 'pointer',
    width: 20,
  },
});

function PickingReports() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [partialPickings, setPartialPickings] = useState([]);
  const [totalPickings, setTotalPickings] = useState(0);
  const [selectedPicking, setSelectedPicking] = useState(null);
  const [initialSelectedPicking, setInitialSelectedPicking] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [loadingPickingsList, setLoadingPickingsList] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [blockSaveButton, setBlockSaveButton] = useState(true);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, partialPickings],
  );

  useEffect(() => {
    const getPickings = async () => {
      setLoadingPickingsList(true);
      try {
        const { data } = await Axios.get('/reports', {
          params: {
            skip: page * limit - limit,
            limit,
          },
        });
        setPartialPickings(data.partialPickings);
        setTotalPickings(data.totalResults);
        setLoadingPickingsList(false);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar a imagens',
          error,
        });
        setLoadingPickingsList(false);
      }
    };
    getPickings();
  }, [page]);

  const handleGeneratePickingReport = async (pickingId) => {
    try {
      const response = await Axios.post('/reports/pickings', {
        pickingId,
      });
      window.open(response.data.urlReport);
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: error.message,
        error,
      });
    }
  };

  const handleSelectPicking = (picking) => {
    setSelectedPicking(picking);
    setInitialSelectedPicking(picking);
  };

  const handleGetOrder = async (orderId) => {
    setLoadingOrder(true);
    const response = await getOrder(orderId);
    setSelectedOrder(response);
    setLoadingOrder(false);
  };

  const handleChangeConfirmIncompletePicking = () => {
    if (selectedPicking.allowConfirmIncompletePicking.status) {
      return setSelectedPicking({
        ...selectedPicking,
        allowConfirmIncompletePicking: {
          ...selectedPicking.allowConfirmIncompletePicking,
          status: false,
        },
      });
    }

    if (!password || password !== '142536') {
      return setSnackbar({
        open: true,
        message: 'Senha inválida',
        type: 'error',
      });
    }

    setPassword('');
    return setSelectedPicking({
      ...selectedPicking,
      allowConfirmIncompletePicking: {
        ...selectedPicking.allowConfirmIncompletePicking,
        status: true,
      },
    });
  };

  const handleChangeOrdersOutOfRoute = (_, values) => {
    const typedValues = values.map((value) => Number(value));

    setSelectedPicking({
      ...selectedPicking,
      ordersOutOfRoute: typedValues,
    });
  };

  const handleSavePickingForm = async () => {
    try {
      const response = await Axios.put('/orders-picking/update', {
        pickingId: selectedPicking.pickingId,
        ordersOutOfRoute: selectedPicking.ordersOutOfRoute,
        allowConfirmIncompletePicking: selectedPicking.allowConfirmIncompletePicking.status,
      });
      setSelectedPicking(response.data);
      setInitialSelectedPicking(response.data);
      setSnackbar({
        open: true,
        message: 'Coleta salva com sucesso',
        type: 'success',
      });
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao salvar a coleta',
        error,
      });
    }
  };

  useEffect(() => {
    if (JSON.stringify(selectedPicking) === JSON.stringify(initialSelectedPicking)) {
      return setBlockSaveButton(true);
    }
    return setBlockSaveButton(false);
  }, [selectedPicking]);

  return (
    <>
      <SimpleBackdrop loading={loadingPickingsList} absolutePosition />
      <Box marginTop={2}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.smallCell}>
                    Coleta nº
                  </TableCell>
                  <TableCell align="center" className={classes.smallCell}>
                    Transportadora
                  </TableCell>
                  <TableCell align="center" className={classes.mediumCell}>
                    Criado por
                  </TableCell>
                  <TableCell align="center" className={classes.smallCell}>
                    Data
                  </TableCell>
                  <TableCell align="center" className={classes.smallCell}>
                    Status
                  </TableCell>
                  <TableCell align="center" className={classes.smallCell}>
                    Nº de volumes
                  </TableCell>
                  <TableCell align="center" className={classes.smallCell}>
                    &nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partialPickings && partialPickings.length
                  ? partialPickings.map((partialPicking) => {
                      const totalVolumes = partialPicking.orders.reduce(
                        (total, order) => total + order.volume,
                        0,
                      );
                      return (
                        <TableRow
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleSelectPicking(partialPicking)}
                          key={partialPicking.pickingId}
                        >
                          <TableCell align="center" className={classes.smallCell}>
                            {partialPicking.pickingId}
                          </TableCell>
                          <TableCell align="center" className={classes.smallCell}>
                            {partialPicking.shippingCompany}
                          </TableCell>
                          <TableCell align="center" className={classes.mediumCell}>
                            {partialPicking.user.name}
                          </TableCell>
                          <TableCell align="center" className={classes.smallCell}>
                            {formatDate(partialPicking.createdAt)}
                          </TableCell>
                          <TableCell align="center" className={classes.smallCell}>
                            {partialPicking.status}
                          </TableCell>
                          <TableCell align="center" className={classes.smallCell}>
                            {totalVolumes}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.printButton}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleGeneratePickingReport(partialPicking.pickingId);
                            }}
                          >
                            <PrintOutlined />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
                {page !== 1 && partialPickings.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography
                            onClick={(event) => handleChangePage(event, 1)}
                            className={classes.typographyCursor}
                          >
                            Nenhuma coleta nesta página, clique aqui para voltar a página.
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}

                {page === 1 && partialPickings.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <Typography>
                            {loadingPickingsList ? 'Carregando...' : 'Nenhuma coleta para exibir'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7}>
                    <CustomPagination
                      page={page}
                      total={totalPickings}
                      limit={limit}
                      handleChangePage={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {selectedPicking ? (
        <SimpleDialog
          cancelButtonText="Cancelar"
          confirmButtonText="Salvar"
          content={
            <>
              <SimpleBackdrop loading={loadingOrder} absolutePosition />
              <Box width={550}>
                <Grid container direction="row" alignItems="center" spacing={1} marginBottom={1}>
                  <Grid item xs={6}>
                    <Typography>
                      Confirmar coleta incompleta:{' '}
                      {selectedPicking.allowConfirmIncompletePicking.status ? 'Sim' : 'Não'}
                    </Typography>
                  </Grid>
                  {selectedPicking.status === 'Criado' &&
                  !selectedPicking.allowConfirmIncompletePicking.status ? (
                    <Grid item xs={4}>
                      <TextField
                        label="Senha"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        name="password"
                        onChange={(event) => setPassword(event.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ) : null}
                  {selectedPicking.status === 'Criado' ? (
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleChangeConfirmIncompletePicking}
                      >
                        {selectedPicking.allowConfirmIncompletePicking.status
                          ? 'Bloquear'
                          : 'Liberar'}
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
                <Autocomplete
                  style={{ marginBottom: 10 }}
                  multiple
                  options={[]}
                  disabled={selectedPicking.status !== 'Criado'}
                  value={selectedPicking.ordersOutOfRoute}
                  freeSolo
                  onChange={handleChangeOrdersOutOfRoute}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...restProps } = getTagProps({ index });
                      return <Chip key={key} variant="outlined" label={option} {...restProps} />;
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pedidos fora da rota"
                      placeholder="Adicionar pedidos fora da rota"
                    />
                  )}
                />
                {selectedPicking.ordersOfRouteUnbeepeds.length ? (
                  <Autocomplete
                    style={{ marginBottom: 10 }}
                    options={[]}
                    multiple
                    disabled
                    value={selectedPicking.ordersOfRouteUnbeepeds}
                    freeSolo
                    onChange={handleChangeOrdersOutOfRoute}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...restProps } = getTagProps({ index });
                        return <Chip key={key} variant="outlined" label={option} {...restProps} />;
                      })
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Pedidos da rota não bipados" />
                    )}
                  />
                ) : null}
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" className={classes.smallCell}>
                            Pedido nº
                          </TableCell>
                          <TableCell align="center" className={classes.mediumCell}>
                            Cliente
                          </TableCell>
                          <TableCell align="center" className={classes.smallCell}>
                            Nº de volumes
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedPicking.orders && selectedPicking.orders.length
                          ? selectedPicking.orders.map((order) => (
                              <TableRow
                                style={{ cursor: 'pointer' }}
                                key={order.orderId}
                                onClick={() => handleGetOrder(order.orderId)}
                              >
                                <TableCell align="center" className={classes.smallCell}>
                                  {order.orderId}
                                </TableCell>
                                <TableCell align="center" className={classes.mediumCell}>
                                  {order.customer.name}
                                </TableCell>
                                <TableCell align="center" className={classes.smallCell}>
                                  {order.volume}
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </>
          }
          dialogTitle={`Detalhes da coleta nº ${selectedPicking.pickingId}`}
          handleCancelButton={() => setSelectedPicking(null)}
          handleClose={() => setSelectedPicking(null)}
          handleConfirmButton={handleSavePickingForm}
          openDialog={!!selectedPicking}
          disableConfirmButton={blockSaveButton}
        />
      ) : null}
      {selectedOrder ? (
        <OrderDetails
          openOrderDetails={!!selectedOrder}
          closeOrderDetails={() => setSelectedOrder(null)}
          selectedOrder={selectedOrder}
        />
      ) : null}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default PickingReports;
