import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { numberToReal } from '../../../../../../../../helpers/formatData';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  sku: {
    width: '5%',
    textAlign: 'center',
    fontSize: 8,
  },
  name: {
    width: '45%',
    margin: 1,
    fontSize: 8,
  },
  ncm: {
    width: '10%',
    textAlign: 'center',
    fontSize: 8,
  },
  localization: {
    width: '11%',
    textAlign: 'center',
    fontSize: 8,
  },
  price: {
    width: '12%',
    textAlign: 'center',
    fontSize: 8,
  },
  quantity: {
    width: '5%',
    textAlign: 'center',
    fontSize: 8,
  },
  total: {
    width: '12%',
    textAlign: 'center',
    fontSize: 8,
  },
});

function TableRow({ items }) {
  const rows = items.map((item) => {
    let name;
    if (item.composition) {
      if (item.name.length > 40) {
        name = `${item.name.substring(0, 40).trim()}...`;
      } else {
        name = item.name;
      }
    } else if (item.technicalName.length > 40) {
      name = `${item.technicalName.substring(0, 40).trim()}...`;
    } else {
      name = item.technicalName;
    }
    return (
      <View style={styles.row} key={item.productId}>
        <Text style={styles.sku}>{item.productId}</Text>
        <Text style={styles.name}>{name}</Text>
        <Text style={styles.ncm}>{item.taxFields.ncm}</Text>
        <Text style={styles.localization}>{item.localization}</Text>
        <Text style={styles.price}>R$ {numberToReal(item.selectedPrice)}</Text>
        <Text style={styles.quantity}>{item.quantity}</Text>
        <Text style={styles.total}>R$ {numberToReal(item.selectedPrice * item.quantity)}</Text>
      </View>
    );
  });

  return rows;
}

export default TableRow;
