import { Autocomplete, TextField, Typography } from '@mui/material';
import React, { memo } from 'react';
import brazilianStates from '../../../constant/brazilianStates';

function BrStatesAutocomplete({
  handleOnChange = () => {},
  selectedState,
  label = 'Estado',
  disabled,
  error,
  variant,
  className,
}) {
  return (
    <Autocomplete
      size="small"
      options={brazilianStates}
      getOptionLabel={(option) => `${option.initials} - ${option.name}`}
      isOptionEqualToValue={(option, value) => option.initials === value.initials}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={key} {...restProps} variant="caption">
            {option.initials} - {option.name}
          </Typography>
        );
      }}
      disabled={disabled}
      value={selectedState}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(BrStatesAutocomplete);
