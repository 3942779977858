import Axios from 'axios';
import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { formatHandleError } from '../../../helpers/formatData';

function UnityAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedUnity,
  label = 'Unidade',
  disabled,
  error,
  variant,
}) {
  const [unitiesList, setUnitiesList] = useState([]);
  const [loadingUnitiesList, setLoadingUnitiesList] = useState(false);

  const filterUnity = createFilterOptions({
    stringify: (option) => option.name,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingUnitiesList(true);
        const response = await Axios.get('/entries/unity');
        const unities = response.data;
        setUnitiesList(unities);
        setLoadingUnitiesList(false);
      } catch (err) {
        setLoadingUnitiesList(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao tentar carregar as unidades',
          error: err,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <Autocomplete
      size="small"
      options={unitiesList}
      filterOptions={filterUnity}
      getOptionLabel={(option) => `${option.name} (${option.quantity})`}
      isOptionEqualToValue={(option, value) => option.unityId === value.unityId}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={option._id} {...restProps} variant="caption">
            {option.name} ({option.quantity})
          </Typography>
        );
      }}
      disabled={disabled}
      value={
        selectedUnity?.unityId
          ? selectedUnity
          : unitiesList.find((unityFind) => unityFind?.name === selectedUnity?.name) || null
      }
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      loading={loadingUnitiesList}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
    />
  );
}

export default memo(UnityAutocomplete);
