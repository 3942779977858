import Axios from 'axios';
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import SimpleDialog from '../../../../Common/SimpleDialog';
import CustomInput from '../../../../CustomInput';
import { formatDateWithHour } from '../../../../../helpers/formatData';
import { getUsers } from '../../../../../services/melhorGestao/users';

function OccurrencesDialog({ openOccurrencesDialog, handleCloseOccurrencesDialog, orderForm }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [openAddOccurrenceDialog, setOpenAddOccurrenceDialog] = useState(false);
  const handleOpenAddOccurrenceDialog = () => setOpenAddOccurrenceDialog(true);
  const handleCloseAddOccurrenceDialog = () => setOpenAddOccurrenceDialog(false);

  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [occurrenceForm, setOccurrenceForm] = useState({
    orderId: orderForm.orderId,
    type: '',
    description: '',
    status: 'Pendente',
  });
  const [occurrencesList, setOccurrencesList] = useState([]);
  const [occurrenceTypes, setOccurrenceTypes] = useState([]);
  const [loadingOccurrences, setloadingOccurrences] = useState(false);
  const [refreshToken, setRefreshToken] = useState(Math.random());
  const handleRefreshOccurrences = () => setRefreshToken(Math.random());

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setOccurrenceForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const handleChangeType = (event, newEvent) => {
    if (newEvent?.name) {
      setOccurrenceForm((oldFields) => ({
        ...oldFields,
        type: newEvent.name,
      }));
    }
  };

  const handleAddOccurrence = async () => {
    try {
      const response = await Axios.post('/occurrences', {
        occurrence: occurrenceForm,
      });

      const users = await getUsers([response.data.createdByUser, response.data.updatedByUser]);
      const findById = (id) => users.find((user) => user._id === id);

      setOccurrencesList((oldOccurrences) => [
        ...oldOccurrences,
        {
          ...response.data,
          createdByUser: findById(response.data.createdByUser).name,
          updatedByUser: findById(response.data.updatedByUser).name,
        },
      ]);

      handleCloseAddOccurrenceDialog();
    } catch (error) {
      let errorMessage = 'Algum erro ocorreu ao adicionar a ocorrência';
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      setSnackbar({
        message: errorMessage,
        open: true,
        type: 'error',
      });
    }
  };

  const handleUpdateOccurrenceStatus = async (occurrenceId) => {
    await Axios.put(`/occurrences/status/${occurrenceId}`);
    handleRefreshOccurrences();
  };

  useEffect(() => {
    if (occurrenceForm.type && occurrenceForm.description && occurrenceForm.status) {
      setDisableConfirmButton(false);
    } else {
      setDisableConfirmButton(true);
    }
  }, [occurrenceForm]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloadingOccurrences(true);

        const responseOccurrences = await Axios.get(`/occurrences/order/${orderForm.orderId}`);

        const createdByUsersIds = responseOccurrences.data.map(
          (ccurrence) => ccurrence.createdByUser,
        );
        const updatedByUsersIds = responseOccurrences.data.map(
          (ccurrence) => ccurrence.updatedByUser,
        );

        const users = await getUsers([...createdByUsersIds, ...updatedByUsersIds]);
        const findById = (id) => users.find((user) => user._id === id);

        setOccurrencesList(
          responseOccurrences.data.map((ccurrence) => ({
            ...ccurrence,
            createdByUser: findById(ccurrence.createdByUser).name,
            updatedByUser: findById(ccurrence.updatedByUser).name,
          })),
        );

        const responseOccurrenceTypes = await Axios.get('/occurrences/types');
        setOccurrenceTypes(responseOccurrenceTypes.data);

        setloadingOccurrences(false);
      } catch (error) {
        let errorMessage = 'Algum erro ocorreu ao carregar os itens';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        setloadingOccurrences(false);
        setSnackbar({
          message: errorMessage,
          open: true,
          type: 'error',
        });
      }
    };
    fetchData();
  }, [refreshToken]);

  const filterTypes = createFilterOptions({
    stringify: (option) => option.name,
  });

  return (
    <>
      <SimpleDialog
        openDialog={openOccurrencesDialog}
        handleClose={handleCloseOccurrencesDialog}
        dialogTitle="Ocorrências"
        dialogText="Registro de ocorrências do pedido"
        confirmButtonText="Adicionar"
        handleConfirmButton={handleOpenAddOccurrenceDialog}
        content={
          <Grid>
            {occurrencesList && occurrencesList.length > 0 ? (
              occurrencesList.map((occurrence, index) => (
                <List key={occurrence._id}>
                  <ListItem>
                    <ListItemIcon>
                      <Typography variant="h4">{index + 1}</Typography>
                    </ListItemIcon>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Grid item>
                            <ListItemText
                              primary={occurrence.type}
                              secondary={occurrence.description}
                            />
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title={
                                <Typography>
                                  {`Clique para marcar como ${
                                    occurrence.status === 'Resolvido' ? 'Pendente' : 'Resolvido'
                                  }`}
                                </Typography>
                              }
                            >
                              <Button
                                variant="outlined"
                                onClick={() => handleUpdateOccurrenceStatus(occurrence._id)}
                              >
                                {occurrence.status}
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            {`Criada por ${occurrence.createdByUser} em ${formatDateWithHour(
                              occurrence.createdAt,
                            )}`}
                          </Grid>
                          <Grid item>
                            {`Atualizada por ${occurrence.updatedByUser} em ${formatDateWithHour(
                              occurrence.updatedAt,
                            )}`}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              ))
            ) : (
              <Typography>Este pedido não possui ocorrências</Typography>
            )}
          </Grid>
        }
      />
      {openAddOccurrenceDialog ? (
        <SimpleDialog
          openDialog={openAddOccurrenceDialog}
          handleClose={handleCloseAddOccurrenceDialog}
          dialogTitle="Adicionar ocorrência"
          dialogText="Adiciona uma ocorrência no pedido"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseAddOccurrenceDialog}
          handleConfirmButton={handleAddOccurrence}
          content={
            <Grid container justifyContent="space-between" direction="column" spacing={1}>
              <Grid item>
                <Autocomplete
                  size="small"
                  options={occurrenceTypes}
                  filterOptions={filterTypes}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  renderOption={(props, option) => {
                    const { key, ...restProps } = props;
                    return (
                      <Typography key={option._id} {...restProps} variant="caption">
                        {option.name}
                      </Typography>
                    );
                  }}
                  onChange={handleChangeType}
                  noOptionsText="Tipo de ocorrência não encontrado"
                  loading={loadingOccurrences}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de ocorrência"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingOccurrences ? (
                              <CircularProgress variant="indeterminate" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <CustomInput
                  label="Descrição"
                  name="description"
                  value={occurrenceForm.description}
                  onChange={handleChange}
                  variant="outlined"
                  rows={3}
                />
              </Grid>
              <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                  {snackbar.message}
                </Alert>
              </Snackbar>
            </Grid>
          }
          disableConfirmButton={disableConfirmButton}
        />
      ) : null}
    </>
  );
}

export default OccurrencesDialog;
