import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { getCategoriesByType } from '../../../services/melhorGestao/payments';

function CategoryPaymentAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  type = '',
  selectedCategory,
  parentId,
  label = 'Categoria',
  disabled,
  error,
  variant,
  className,
}) {
  const [searchText, setSearchText] = useState('');
  const [categoriesList, setCategoriesList] = useState([]);
  const [loadingCategoriesList, setLoadingCategoriesList] = useState(false);
  const [timeoutCategorySearchId, setTimeoutCategorySearchId] = useState();

  const searchCategories = async () => {
    setLoadingCategoriesList(true);
    const categories = await getCategoriesByType({
      type,
      setSnackbar,
    });
    if (categories) {
      if (parentId === undefined) {
        setCategoriesList(categories);
      } else {
        setCategoriesList(categories.filter((category) => category.parentId === parentId));
      }
    }
    setLoadingCategoriesList(false);
    return false;
  };

  const filterCategory = createFilterOptions({
    stringify: (option) => option.name,
  });

  useEffect(() => {
    setLoadingCategoriesList(true);
    if (timeoutCategorySearchId) {
      clearTimeout(timeoutCategorySearchId);
    }
    setTimeoutCategorySearchId(setTimeout(() => searchCategories(), 1000));
  }, [searchText, type, parentId]);

  return (
    <Autocomplete
      size="small"
      options={categoriesList}
      filterOptions={filterCategory}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.categoryId === value.categoryId}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={option._id} {...restProps} variant="caption">
            {option.name}
          </Typography>
        );
      }}
      disabled={disabled}
      value={selectedCategory}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue);
      }}
      loading={loadingCategoriesList}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(CategoryPaymentAutocomplete);
