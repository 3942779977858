import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatPhone,
} from '../../../../../../../../helpers/formatData';

const styles = StyleSheet.create({
  customerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 2,
  },
  customerDetailsContainer: {
    width: '100%',
    border: '1',
    borderRadius: 3,
    padding: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fullWidth: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  smallText: {
    fontSize: 8,
  },
});

function Customer({ seller, order }) {
  return (
    <View style={styles.customerContainer}>
      <View style={styles.customerDetailsContainer}>
        <View style={styles.fullWidth}>
          <Text>
            {order.customer.userId
              ? `Cliente: ${order.customer.name} - ${order.customer.userId}`
              : `Cliente: ${order.customer.name}`}
          </Text>
        </View>

        <View style={styles.fullWidth}>
          <Text style={styles.smallText}>
            Endereço: {order.shipping.address.street}, {order.shipping.address.streetNumber}
            {order.shipping.address.complement ? `, ${order.shipping.address.complement}` : ''}
          </Text>
        </View>

        <View style={styles.fullWidth}>
          <Text style={styles.smallText}>Bairro: {order.shipping.address.district}</Text>
        </View>

        <View style={styles.fullWidth}>
          <Text style={styles.smallText}>
            Cidade: {order.shipping.address.city}/{order.shipping.address.state}, CEP:{' '}
            {order.shipping.address.postalCode}
          </Text>
        </View>

        <View style={styles.fullWidth}>
          {order.customer?.phones?.length > 0 ? (
            <Text style={styles.smallText}>
              Telefone{order.customer.phones.length > 1 ? 's' : ''}:{' '}
              {order.customer.phones.map((phone, index) => {
                if (index === 0) {
                  return formatPhone(phone.number);
                }
                return `, ${formatPhone(phone.number)}`;
              })}
            </Text>
          ) : null}
          <Text style={styles.smallText}>
            Vendedor: {capitalizeString(extractFirstAndLastName(seller?.name || order.source))}
          </Text>
        </View>
      </View>
    </View>
  );
}

export default Customer;
