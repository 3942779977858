import {
  Autocomplete,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import simulateMongoObjectId from '../../../../../../../helpers/simulateMongoObjectId';
import {
  getMercadoLivreAttributes,
  getMercadoLivreCategoryChildren,
} from '../../../../../../../services/melhorGestao/mercadoLivre';
import CustomInput from '../../../../../../CustomInput';
import CustomSelect from '../../../../../../CustomSelect';
import { formatHandleError } from '../../../../../../../helpers/formatData';

function MercadoLivreAttribute({
  adForm,
  attributesFieldsFromProduct,
  setAttributesFieldsFromProduct,
  setSnackbar,
}) {
  const [mercadoLivreCategoriesPath, setMercadoLivreCategoriesPath] = useState('');
  const [loadingMercadoLivreAttributes, setLoadingMercadoLivreAttributes] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (adForm.companies.length > 0 && adForm.category?.categoryId) {
        try {
          setLoadingMercadoLivreAttributes(true);

          const categoriesList = await getMercadoLivreCategoryChildren(
            adForm.companies[0],
            adForm.category.categoryId,
          );

          setMercadoLivreCategoriesPath(
            categoriesList.pathFromRoot.map((path) => path.name).join(' / '),
          );

          const attributesList = await getMercadoLivreAttributes(
            adForm.companies[0],
            adForm.category.categoryId,
          );

          setAttributesFieldsFromProduct(
            attributesList.map((attribute) => {
              let ourAttribute;
              if (adForm.attributes && adForm.attributes.length > 0) {
                ourAttribute = adForm.attributes.find(
                  (findAttribute) => findAttribute.id === attribute.id,
                );
              }
              if (ourAttribute) {
                if (attribute.unitsOptions.length > 0 && ourAttribute.unitId) {
                  return {
                    ...attribute,
                    _id: simulateMongoObjectId(),
                    value: ourAttribute.value,
                    unitId: ourAttribute.unitId,
                  };
                }
                return {
                  ...attribute,
                  _id: simulateMongoObjectId(),
                  value: ourAttribute.value,
                };
              }
              if (adForm.brand && attribute.id === 'BRAND') {
                return {
                  ...attribute,
                  _id: simulateMongoObjectId(),
                  value: adForm.brand,
                };
              }
              return { ...attribute, _id: simulateMongoObjectId() };
            }),
          );

          setLoadingMercadoLivreAttributes(false);
        } catch (error) {
          setLoadingMercadoLivreAttributes(false);
          return formatHandleError({
            setSnackbar,
            defaultMessage: 'Algum erro ocorreu ao buscar características do Mercado Livre',
            error,
          });
        }
      }
      return null;
    };
    fetchData();
  }, [adForm]);

  const handleChangeAttributeMultivalued = useCallback(
    (index, value) => {
      const updatedAttributes = attributesFieldsFromProduct;

      updatedAttributes[index] = {
        ...updatedAttributes[index],
        value: value.join(','),
      };

      setAttributesFieldsFromProduct([...updatedAttributes]);
    },
    [attributesFieldsFromProduct],
  );

  const handleChangeAttribute = useCallback(
    (event) => {
      const updatedAttributes = attributesFieldsFromProduct;

      updatedAttributes[event.target.name] = {
        ...updatedAttributes[event.target.name],
        value: event.target.value,
      };

      setAttributesFieldsFromProduct([...updatedAttributes]);
    },
    [attributesFieldsFromProduct],
  );

  const handleChangeAttributeUnit = useCallback(
    (event) => {
      const updatedAttributes = attributesFieldsFromProduct;

      updatedAttributes[event.target.name] = {
        ...updatedAttributes[event.target.name],
        unitId: event.target.value || updatedAttributes[event.target.name].unitsOptions[0].id,
      };

      setAttributesFieldsFromProduct([...updatedAttributes]);
    },
    [attributesFieldsFromProduct],
  );

  const setAttributeToEmpty = (index, value) => {
    const updatedAttributes = attributesFieldsFromProduct;
    updatedAttributes[index] = {
      ...updatedAttributes[index],
      value: value === -1 ? '' : -1,
    };

    setAttributesFieldsFromProduct([...updatedAttributes]);
  };

  const handleLabelLimit = ({ text, withUnit, isHelperText }) => {
    const lengthLimit = withUnit ? 23 : 33;

    if (text.length > lengthLimit) {
      if (isHelperText) {
        return `...${text.substring(lengthLimit).trim()}`;
      }
      return `${text.substring(0, lengthLimit).trim()}...`;
    }
    if (isHelperText) {
      return null;
    }
    return text;
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="caption">{mercadoLivreCategoriesPath}</Typography>
      </Grid>
      <Grid item />
      {!loadingMercadoLivreAttributes &&
        attributesFieldsFromProduct.map((attribute, index) => (
          <Grid key={attribute._id} item>
            {attribute.comboOptions && attribute.comboOptions.length > 0 ? (
              <Grid container alignItems="center" spacing={1}>
                {attribute.multivalued ? (
                  <Grid item xs={9}>
                    <Autocomplete
                      fullWidth
                      multiple
                      options={attribute.comboOptions.map((option) => option.name)}
                      freeSolo
                      name={String(index)}
                      disabled={attribute.value === -1}
                      value={
                        attribute.value && attribute.value.length > 0
                          ? attribute.value.split(',')
                          : []
                      }
                      onChange={(event, value) => handleChangeAttributeMultivalued(index, value)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, indexValue) => {
                          const { key, ...restProps } = getTagProps({ index: indexValue });
                          return <Chip key={key} {...restProps} size="small" label={option} />;
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={handleLabelLimit({ text: attribute.label })}
                          helperText={handleLabelLimit({
                            text: attribute.label,
                            isHelperText: true,
                          })}
                          size="small"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={9}>
                    <CustomSelect
                      label={handleLabelLimit({ text: attribute.label })}
                      helperText={handleLabelLimit({ text: attribute.label, isHelperText: true })}
                      comboOptions={attribute.comboOptions}
                      allowCustomValue
                      name={String(index)}
                      disabled={attribute.value === -1}
                      onInputChange={handleChangeAttribute}
                      onChange={handleChangeAttribute}
                      value={attribute.value !== -1 ? attribute.value || '' : ''}
                    />
                  </Grid>
                )}
                {!attribute.required ? (
                  <Grid item xs={3}>
                    <IconButton
                      size="small"
                      color={attribute.value === -1 ? 'secondary' : 'default'}
                      onClick={() => setAttributeToEmpty(index, attribute.value)}
                    >
                      N/A
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            ) : (
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={attribute.unitsOptions && attribute.unitsOptions.length > 0 ? 6 : 9}>
                  <CustomInput
                    name={String(index)}
                    label={handleLabelLimit({
                      text: attribute.label,
                      withUnit: attribute.unitsOptions && attribute.unitsOptions.length > 0,
                    })}
                    helperText={handleLabelLimit({
                      text: attribute.label,
                      withUnit: attribute.unitsOptions && attribute.unitsOptions.length > 0,
                      isHelperText: true,
                    })}
                    value={attribute.value !== -1 ? attribute.value || '' : ''}
                    onChange={handleChangeAttribute}
                    number={
                      attribute.valueType === 'number_unit' ||
                      (attribute.valueType === 'number' && attribute.value !== -1)
                    }
                    disabled={attribute.value === -1}
                    variant="outlined"
                  />
                </Grid>
                {attribute.unitsOptions && attribute.unitsOptions.length > 0 ? (
                  <Grid
                    item
                    xs={attribute.unitsOptions && attribute.unitsOptions.length > 0 ? 3 : 6}
                  >
                    <CustomSelect
                      comboOptions={attribute.unitsOptions}
                      allowCustomValue
                      name={String(index)}
                      disabled={attribute.value === -1}
                      onChange={handleChangeAttributeUnit}
                      value={attribute.unitId}
                    />
                  </Grid>
                ) : null}
                {!attribute.required ? (
                  <Grid item xs={3}>
                    <IconButton
                      size="small"
                      color={attribute.value === -1 ? 'secondary' : 'default'}
                      onClick={() => setAttributeToEmpty(index, attribute.value)}
                    >
                      N/A
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            )}
          </Grid>
        ))}
      {!loadingMercadoLivreAttributes && attributesFieldsFromProduct.length === 0 ? (
        <Grid item>
          <Typography>Nenhum atributo para ser preenchido</Typography>
        </Grid>
      ) : null}
      {loadingMercadoLivreAttributes ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default MercadoLivreAttribute;
