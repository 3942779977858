import Axios from 'axios';
import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../../helpers/formatData';

function CompanyAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedCompany,
  setSelectedCompany = () => {},
  filterCompaniesByIds,
  label = 'Empresa',
  disabled,
  error,
  variant,
  size = 'small',
  className,
}) {
  const [companiesList, setCompaniesList] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const filterCompany = createFilterOptions({
    stringify: (option) => option.name + option.cnpj + option.nomeFantasia + option.razaoSocial,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingCompanies(true);
        const response = await Axios.get('/configuration/companies');
        const companies = response.data;

        if (filterCompaniesByIds?.length > 0) {
          setCompaniesList(
            companies.filter((company) => filterCompaniesByIds.includes(company._id)),
          );
        } else {
          setCompaniesList(companies);
        }

        if (typeof selectedCompany === 'object') {
          setSelectedCompany(selectedCompany);
          setSelectedValue(selectedCompany);
        } else {
          const company = companies.find(
            (cp) => cp._id === selectedCompany || cp.companyId === selectedCompany,
          );

          if (company) {
            setSelectedCompany(company);
            setSelectedValue(company);
          }
        }
        setLoadingCompanies(false);
      } catch (err) {
        setLoadingCompanies(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar as empresas',
          error: err,
        });
      }
    };
    fetchData();
  }, [selectedCompany, filterCompaniesByIds]);

  return (
    <Autocomplete
      size={size}
      options={companiesList}
      filterOptions={filterCompany}
      getOptionLabel={(option) =>
        `${option.companyId} - ${capitalizeString(extractFirstAndLastName(option.name))}`
      }
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={option._id} {...restProps}>
            {option.companyId} - {capitalizeString(extractFirstAndLastName(option.name))}
          </Typography>
        );
      }}
      disabled={disabled}
      value={selectedValue}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      loading={loadingCompanies}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(CompanyAutocomplete);
