import { Autocomplete, TextField, Typography, createFilterOptions } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import { searchProducts } from '../../../services/melhorGestao/products';

function ProductAutocomplete({
  setSnackbar = () => {},
  handleOnChange = () => {},
  selectedProduct,
  label = 'Produto',
  disabled,
  error,
  variant,
  size = 'small',
  className,
}) {
  const [searchText, setSearchText] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [timeoutProductSearchId, setTimeoutProductSearchId] = useState();

  const handleSearchAllProducts = async () => {
    searchProducts({
      searchText,
      setLoadingProductsList,
      setProductsList,
      setSnackbar,
    });
  };

  const filterProduct = createFilterOptions({
    stringify: (option) => option.name + option.brand + option.productId,
  });

  useEffect(() => {
    setLoadingProductsList(true);
    if (timeoutProductSearchId) {
      clearTimeout(timeoutProductSearchId);
    }
    setTimeoutProductSearchId(setTimeout(() => handleSearchAllProducts(), 1000));
  }, [searchText]);

  return (
    <Autocomplete
      size={size}
      options={productsList}
      filterOptions={filterProduct}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.productId === value.productId}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        return (
          <Typography key={option._id} {...restProps} variant="caption">
            {option.productId} - {option.name}
          </Typography>
        );
      }}
      disabled={disabled}
      value={selectedProduct}
      onChange={handleOnChange}
      noOptionsText="Opção não encontrada"
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue);
      }}
      loading={loadingProductsList}
      renderInput={(params) => (
        <TextField {...params} label={label} variant={variant} error={error} />
      )}
      className={className}
    />
  );
}

export default memo(ProductAutocomplete);
