import Axios from 'axios';
import { Button, Chip, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import { cancelInvoice, createInvoiceCorrection } from '../../../../services/melhorGestao/invoices';
import InvoiceInquiryLink from '../../../Common/InvoiceInquiryLink';
import OthersInvoicesButton from './OthersInvoicesButton';
import { formatHandleError } from '../../../../helpers/formatData';

function InvoiceActonsBlok({
  orderId,
  invoice,
  setOrderForm,
  setInitialOrderForm,
  setSnackbar,
  hideButtons,
}) {
  const [loadingCorrectionInvoices, setLoadingCorrectionInvoices] = useState(false);
  const [loadingCancelInvoices, setLoadingCancelInvoices] = useState(false);

  const [openCorrectionInvoiceDialog, setOpenCorrectionInvoiceDialog] = useState(false);
  const handleOpenCorrectionNfDialog = () => setOpenCorrectionInvoiceDialog(true);
  const handleCloseCorrectionNfDialog = () => setOpenCorrectionInvoiceDialog(false);
  const [correctionInvoiceReason, setCorrectionInvoiceReason] = useState('');

  const handleConfirmCorrectionInvoice = async () => {
    const results = await createInvoiceCorrection({
      setSnackbar,
      setLoadingInvoices: setLoadingCorrectionInvoices,
      invoiceKey: invoice.key,
      correction: correctionInvoiceReason,
    });

    if (!results) {
      handleCloseCorrectionNfDialog();
      return null;
    }

    const { status, dacce, error } = results;

    if (status === 'aprovado' && dacce) {
      handleCloseCorrectionNfDialog();
      return window.open(dacce);
    }

    if (error) {
      handleCloseCorrectionNfDialog();
      return setSnackbar({
        message: error,
        open: true,
        type: 'error',
      });
    }

    handleCloseCorrectionNfDialog();
    return setSnackbar({
      message: 'Algum erro ocorreu ao emitir carta de correção da Nota Fiscal',
      open: true,
      type: 'error',
    });
  };

  const [openCancelInvoiceDialog, setOpenCancelInvoiceDialog] = useState(false);
  const handleOpenCancelNfDialog = () => setOpenCancelInvoiceDialog(true);
  const handleCloseCancelNfDialog = () => setOpenCancelInvoiceDialog(false);
  const [cancelInvoiceReason, setCancelInvoiceReason] = useState(
    'Cancelamento por motivos administrativos.',
  );

  const handleConfirmCancelInvoice = async () => {
    const results = await cancelInvoice({
      setSnackbar,
      setLoadingInvoices: setLoadingCancelInvoices,
      orderId,
      reason: cancelInvoiceReason,
    });

    if (!results) {
      handleCloseCancelNfDialog();
      return null;
    }

    const { status, xml } = results;

    if (status === 'cancelado' && xml) {
      setLoadingCancelInvoices(true);

      try {
        const responseOrder = await Axios.get(`/orders/${orderId}`);
        const getUpdatedOrder = responseOrder.data.order;

        setOrderForm(getUpdatedOrder);
        setInitialOrderForm(getUpdatedOrder);

        setLoadingCancelInvoices(false);
        handleCloseCancelNfDialog();

        return setSnackbar({
          message: 'Nota Fiscal cancelada com sucesso',
          open: true,
          type: 'success',
        });
      } catch (error) {
        setLoadingCancelInvoices(false);
        handleCloseCancelNfDialog();

        return formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar o pedido',
          error,
        });
      }
    }

    handleCloseCancelNfDialog();

    return setSnackbar({
      message: 'Algum erro ocorreu ao cancelar a Nota Fiscal',
      open: true,
      type: 'error',
    });
  };

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>Nota Fiscal:</Grid>
                <Grid item>
                  <Typography variant="h3">{invoice.number}</Typography>
                </Grid>
                {invoice?.danfe ? (
                  <Grid item>
                    <Chip onClick={() => window.open(invoice.danfe)} size="small" label="DANFE" />
                  </Grid>
                ) : (
                  <Grid item>
                    <InvoiceInquiryLink invoice={invoice} />
                  </Grid>
                )}
                {invoice?.xml ? (
                  <Grid item>
                    <Chip onClick={() => window.open(invoice.xml)} size="small" label="XML" />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item>
              <OthersInvoicesButton orderId={orderId} setSnackbar={setSnackbar} />
            </Grid>
          </Grid>
        </Grid>
        {!hideButtons ? (
          <Grid item>
            <Grid container spacing={1}>
              <Tooltip
                title={
                  <Typography align="center">
                    Emitir carta de correção (CCe) para a Nota Fiscal
                  </Typography>
                }
              >
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleOpenCorrectionNfDialog}
                  >
                    Corrigir NF
                  </Button>
                </Grid>
              </Tooltip>
              <Tooltip
                title={
                  <Typography align="center">
                    {dayjs(invoice.emissionDate).isSame(dayjs(), 'day')
                      ? 'Emitir cancelamento da Nota Fiscal'
                      : 'Apenas pedido faturado ou embalado dentro de 24h (1 dia) pode ter a Nota Fiscal cancelada'}
                  </Typography>
                }
              >
                <Grid item>
                  <Button color="secondary" variant="contained" onClick={handleOpenCancelNfDialog}>
                    Cancelar NF
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {openCorrectionInvoiceDialog ? (
        <SimpleDialog
          openDialog={openCorrectionInvoiceDialog}
          handleClose={handleCloseCorrectionNfDialog}
          dialogTitle={`Correção da Nota Fiscal nº ${invoice.number}`}
          dialogText="A Carta de Correção Eletrônica (CC-e) é um evento legal e tem por objetivo corrigir algumas informações da NF-e que já foi emitida."
          content={
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  O que NÃO é permitido corrigir com a carta de correção?
                </Typography>
                <Typography>
                  - Valores como base de cálculo, alíquota, diferença de preço e quantidade.
                </Typography>
                <Typography>
                  - Dados cadastrais que implique mudança do remetente ou do destinatário.
                </Typography>
                <Typography>- A data de emissão ou de saída.</Typography>
                <Typography>- Série e número da nota fiscal.</Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  label="Correção a considerar"
                  variant="outlined"
                  rows={4}
                  value={correctionInvoiceReason}
                  onChange={(event) => setCorrectionInvoiceReason(event.target.value)}
                />
              </Grid>
            </Grid>
          }
          maxWidth="sm"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirma"
          confirmButtonLoading={loadingCorrectionInvoices}
          handleCancelButton={handleCloseCorrectionNfDialog}
          handleConfirmButton={handleConfirmCorrectionInvoice}
        />
      ) : null}
      {openCancelInvoiceDialog ? (
        <SimpleDialog
          openDialog={openCancelInvoiceDialog}
          handleClose={handleCloseCancelNfDialog}
          dialogTitle={`Cancelar Nota Fiscal nº ${invoice.number}`}
          dialogText="Atualmente o prazo máximo para cancelamento de uma NF-e é de 24 horas (1 dia), contado a partir da autorização de uso. Caso já tenha passado o prazo de 24 horas ou já tenha ocorrido a circulação da mercadoria, emita uma NF-e de devolução para anular a NF-e anterior."
          content={
            <CustomInput
              label="Motivo"
              variant="outlined"
              value={cancelInvoiceReason}
              onChange={(event) => setCancelInvoiceReason(event.target.value)}
            />
          }
          maxWidth="sm"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirma"
          confirmButtonLoading={loadingCancelInvoices}
          handleCancelButton={handleCloseCancelNfDialog}
          handleConfirmButton={handleConfirmCancelInvoice}
        />
      ) : null}
    </Grid>
  );
}

export default InvoiceActonsBlok;
